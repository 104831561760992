<template>
  <div>
    <h1 class="header">Discover vegan recipe wizard</h1>

    <p>Start with a broad search. This can be an ingredient, category or cuisine.</p>
    <p class="text-hint">Example searches: 'dessert', 'salad', 'italian' or 'avocado'.</p>
    <v-text-field
      prepend-icon="fa-search" 
      label="Search by ingredient, category or cuisine"
      hint="Try 'dessert', 'salad', 'italian' or 'avocado'."
      v-model="queryLocal" 
      @keyup.enter="searchRecipes"
    ></v-text-field>
    <p>Add ingredients</p>
    <IngredientSelector v-on:change="selectIngredientFilter" :foodIds="foodIdsLocal"/>

    <h3><strong>Step 2:</strong> Exclude recipes by allergy</h3>
    Optionally, filter out recipes by food allergies.
    <v-select
      :items="$store.state.search.allergies"
      @change="searchRecipes"
      v-model="allergiesLocal" 
      item-value="id"
      item-text="name"
      label="Select one or more allergies"
      multiple
    ></v-select>

    <h3><strong>Step 3:</strong> Focus on a specific nutrient</h3>
    <NutrientSelector v-on:change="selectNutrientFilter" :nutrientId="nutrientIdLocal" @loadNutrient="nutrient = $event"/>

    <div class="mt-8">&nbsp;</div>

    <component
      v-bind:is="'v-fade-transition'"
      hide-on-leave
    >
      <v-row dense v-if="searching">
        <v-col v-for="n in 8" :key="n" cols="12" sm="6" md="4" lg="3">
          <v-skeleton-loader
            height="300"
            type="card-avatar, actions"
          >
          </v-skeleton-loader>
        </v-col>
      </v-row>

      <div v-else>
        <v-alert type="info" v-if="resultCount == 0">
          No results found matching your query. Try searching for a different ingredient.
        </v-alert>
        <v-alert type="success" v-if="resultCount > 0">
          {{resultCount}} recipes have been found matching your criteria.
          <span v-if="nutrient">
            Recipes are sorted by their content of {{ nutrient.name }}. Value per serving of {{ nutrient.name }} is displayed
            <v-chip color="primary">per recipe</v-chip>
          </span>
        </v-alert>

        <RecipeGrid :recipes="recipes" />
      </div>
    </component>
    
  </div>
</template>

<script>

import { mapState } from 'vuex'
import RecipeGrid from "@/components/RecipeGrid.vue";
import IngredientGrid from "@/components/IngredientGrid.vue";
import NutrientSelector from "@/components/NutrientSelector.vue";
import IngredientSelector from "@/components/IngredientSelector.vue";
import nutrientService from "@/services/nutrientService";
import recipeService from "@/services/recipeService";
import foodService from "@/services/foodService";

export default {
  name: "RecipeWizard",
  components: {
    RecipeGrid,
    IngredientGrid,
    NutrientSelector,
    IngredientSelector,
  },
  props: {
  },
  data() {
    return {
      recipes: [],
      selectedStep: 0,
      resultCount: null,
      nutrientIdLocal: null,
      popularFood: null,
      foodIdsLocal: null,
      allergiesLocal: null,
      nutrient: null,
      queryLocal: null,
      showFilter: false,
      searching: false,
    };
  },
  /*computed: mapState([
    // map this.query to store.state.query
    'query'
  ]),*/
  async mounted() {
    await this.$store.dispatch('loadfromUrl');
    if (this.$store.getters.hasSearch) {
      this.queryLocal = this.$store.state.filter.query;
      this.allergiesLocal = this.$store.state.filter.allergies;
      this.foodIdsLocal = this.$store.state.filter.foodIds || [];
      this.nutrientIdLocal = this.$store.state.filter.nutrientId;
      this.searchRecipes(false);
    }

    //this.loadPopularFood();
  },
  methods: {
    loadPopularFood() {
      foodService.getPopular().then(response => {
        this.popularFood = response.data.data;
      }).catch(() => {
        this.popularFood = null;
      });
    },
    selectNutrientFilter(val) {
      console.log("Updating nutrient filter:", val);
      this.nutrientIdLocal = val;
      this.searchRecipes();
    },
    selectIngredientFilter(val) {
      this.foodIdsLocal = val;
      console.log("Updating ingredient filter:", this.foodIdsLocal);
      this.searchRecipes();
    },
    async searchRecipes(updateState = true) {
      var payload = {
        query: this.queryLocal,
        nutrientId: this.nutrientIdLocal,
        allergies: this.allergiesLocal,
        foodIds: this.foodIdsLocal
      };
      if (updateState) {
        this.$store.commit('storeQuery', payload);
        await this.$store.dispatch('storeInUrl');
      }
      var data = this.$store.getters.searchPostData;
      console.log("Searching for recipes with filter: ", data);

      this.searching = true;
      recipeService.postSearch(data).then(response => {
        this.recipes = response.data.data;
        this.resultCount = response.data.meta.count;
      }).catch(() => {
        this.recipes = [];
        this.resultCount = null;
      }).finally(() => {
        this.searching = false;
      });

    },
  },
  computed: {
    hasFilter: function() {
      return this.showFilter || this.$store.getters.hasFilter;
    }
    /*...mapState({
      //searchFilter: state => state.searchFilter,
      //allergies: state => state.allergies,
      //foodIds: state => state.filter.foodIds,
      //query: state => state.query,
      //nutrientId: state => state.filter.nutrientId,
    })*/
  },
  watch: {
    recipes() {
      console.log('Recipes updated! Refresh please', this.recipes);
      this.$forceUpdate();
    }
  }
};
</script>
